/*
headerStylesMaxWidth
headerStylesPaddingX
headerLogoAlignment
headerMenuTextSize
headerMenuTextColor
headerMenuFontWeight
headerMenuHoverTextColor
headerMenuHoverBackgroundColor

//

TitleH1TextSize
TitleH1TextColor
TitleH1FontWeight
TitleH1LineHeight

// 
DescriptionsParagraphTextSize
DescriptionsParagraphTextColor
DescriptionsParagraphFontWeight
DescriptionsParagraphLineHeight

// 
DescriptionsCaptionTextSize
DescriptionsCaptionTextColor
DescriptionsCaptionFontWeight
DescriptionsCaptionLineHeight


// 
HeadingH2TextSize
HeadingH2TextColor
HeadingH2FontWeight
HeadingH2LineHeight

*/

export const typography_configs = ({ is_draft, all_configs }) => {
  let root = document.documentElement;

  const header_menu_typography = is_draft
    ? all_configs?.draft_styles?.styles?.styles?.typography?.header_menu ?? {}
    : all_configs?.styles?.typography?.header_menu ?? {};
  const title_h1 = is_draft
    ? all_configs?.draft_styles?.styles?.styles?.typography?.title_h1 ?? {}
    : all_configs?.styles?.typography?.title_h1 ?? {};
  const heading_h2 = is_draft
    ? all_configs?.draft_styles?.styles?.styles?.typography?.heading_h2 ?? {}
    : all_configs?.styles?.typography?.heading_h2 ?? {};
  const descriptions_paragraph = is_draft
    ? all_configs?.draft_styles?.styles?.styles?.typography
        ?.descriptions_paragraph ?? {}
    : all_configs?.styles?.typography?.descriptions_paragraph ?? {};
  const description_caption = is_draft
    ? all_configs?.draft_styles?.styles?.styles?.typography
        ?.descriptions_caption ?? {}
    : all_configs?.styles?.typography?.description_caption ?? {};

  //Header menu (category)
  root.style.setProperty(
    '--headerMenuTextSize',
    header_menu_typography?.text_size ?? '16px'
  );
  root.style.setProperty(
    '--headerMenuTextColor',
    header_menu_typography?.text_color ?? '#222222'
  );
  root.style.setProperty(
    '--headerMenuFontWeight',
    header_menu_typography?.font_weight ?? '400'
  );

  root.style.setProperty(
    '--headerMenuHoverTextColor',
    header_menu_typography?.hover?.text_color ??
      all_configs?.onboarding?.app_color_primary ??
      '#004327'
  );
  root.style.setProperty(
    '--headerMenuHoverBackgroundColor',
    header_menu_typography?.hover?.background_color ?? '#f2f4f4'
  );

  //Title H1
  root.style.setProperty('--TitleH1TextSize', title_h1?.text_size ?? '30px');
  root.style.setProperty(
    '--TitleH1TextColor',
    title_h1?.text_color ?? 'var(--primary_color)'
  );
  root.style.setProperty('--TitleH1FontWeight', title_h1?.font_weight ?? '600');
  root.style.setProperty(
    '--TitleH1LineHeight',
    title_h1?.line_height ?? '26px'
  );

  //Heading H2
  root.style.setProperty(
    '--HeadingH2TextSize',
    heading_h2?.text_size ?? '20px'
  );
  root.style.setProperty(
    '--HeadingH2TextColor',
    heading_h2?.text_color ?? 'black'
  );
  root.style.setProperty(
    '--HeadingH2FontWeight',
    heading_h2?.font_weight ?? '600'
  );
  root.style.setProperty(
    '--HeadingH2LineHeight',
    heading_h2?.line_height ?? '24x'
  );

  //Description Paragraph
  root.style.setProperty(
    '--DescriptionsParagraphTextSize',
    descriptions_paragraph?.text_size ?? '16px'
  );
  root.style.setProperty(
    '--DescriptionsParagraphTextColor',
    descriptions_paragraph?.text_color ?? 'black'
  );
  root.style.setProperty(
    '--DescriptionsParagraphFontWeight',
    descriptions_paragraph?.font_weight ?? '500'
  );
  root.style.setProperty(
    '--DescriptionsParagraphLineHeight',
    descriptions_paragraph?.line_height ?? '24px'
  );

  //Description Caption
  root.style.setProperty(
    '--DescriptionsCaptionTextSize',
    description_caption?.text_size ?? '14px'
  );
  root.style.setProperty(
    '--DescriptionsCaptionTextColor',
    description_caption?.text_color ?? 'black'
  );
  root.style.setProperty(
    '--DescriptionsCaptionFontWeight',
    description_caption?.font_weight ?? '400'
  );
  root.style.setProperty(
    '--DescriptionsCaptionLineHeight',
    description_caption?.line_height ?? '16px'
  );
};
