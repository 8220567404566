import { getCookie } from 'cookies-next';
import {
  font_sizes,
  font_weight,
  letter_spacing,
  text_cases,
  text_name,
} from './constant_styles_values';
import { all_integrations_data, metadata } from './Metadata';
import { TYPE_CONSTANT } from './Web_constant';
import { listing_card_config_setup } from './configs/ListingCardConfigSet';
import { header_styles_configs } from './configs/Header_styles_configs';
import { typography_configs } from './configs/Typography_configs';
import { listing_card_default_style } from './Styles_dynamic_values';
import { account_card_config_setup } from './configs/AccountCardConfigSet';

export const set_root_configs = ({ is_draft, all_configs }) => {
  let root = document.documentElement;

  // Auth type set
  TYPE_CONSTANT.AUTH_TYPE = all_configs?.general?.auth_type;

  //fonts set
  metadata.primary_font_name = is_draft
    ? all_configs?.draft_styles?.styles?.general?.web_primary_font ||
      'Montserrat'
    : all_configs?.general?.web_primary_font || 'Montserrat';

  metadata.body_font_name = is_draft
    ? all_configs?.draft_styles?.styles?.general?.web_font_title || 'Montserrat'
    : all_configs?.general?.web_font_title || 'Montserrat';
  metadata.title_font_name = is_draft
    ? all_configs?.draft_styles?.styles?.general?.web_font_body || 'Montserrat'
    : all_configs?.general?.web_font_body || 'Montserrat';

  const primary_font = is_draft
    ? all_configs?.draft_styles?.styles?.general?.web_primary_font ||
      'Montserrat'
    : all_configs?.general?.web_primary_font || 'Montserrat';
  const title_font = is_draft
    ? all_configs?.draft_styles?.styles?.general?.web_font_title || 'Montserrat'
    : all_configs?.general?.web_font_title || 'Montserrat';
  const body_font = is_draft
    ? all_configs?.draft_styles?.styles?.general?.web_font_body || 'Montserrat'
    : all_configs?.general?.web_font_body || 'Montserrat';

  root.style.setProperty('--primary_font', primary_font);
  root.style.setProperty('--title_font', title_font);
  root.style.setProperty('--body_font', body_font);

  //   marketplace type
  localStorage.setItem('MARKETPLACE_MODULES', all_configs?.general?.type);
  TYPE_CONSTANT.MARKETPLACE_MODULES = all_configs?.general?.type;
  //   marketplace module
  localStorage.setItem('MARKETPLACE_FLAVOURS', all_configs?.general?.sub_type);
  TYPE_CONSTANT.MARKETPLACE_FLAVOURS = all_configs?.general?.sub_type;

  //Set  theme number
  localStorage.setItem('THEME', all_configs?.general?.theme);
  TYPE_CONSTANT.THEME = all_configs?.general?.theme;

  //Set Primary menus
  TYPE_CONSTANT.PRIMARY_MENUS = all_configs?.general?.primary_menus;

  // logo
  localStorage.setItem(
    'logo',
    is_draft
      ? all_configs?.draft_styles?.styles?.general?.web_logo
      : all_configs?.general?.web_logo
  );

  // favicon
  metadata.favicon = is_draft
    ? all_configs?.draft_styles?.styles?.general?.web_icon
    : all_configs?.general?.web_icon;

  //   primary color , secondary color , footer color
  const primary_color = is_draft
    ? all_configs?.draft_styles?.styles?.onboarding?.app_color_primary ||
      '#004327'
    : all_configs?.onboarding?.app_color_primary || '#004327';
  const primary_light_color = is_draft
    ? all_configs?.draft_styles?.styles?.onboarding?.app_color_primary_light ||
      '#ECF4F0'
    : all_configs?.onboarding?.app_color_primary_light || '#ECF4F0';
  const secondary_color = is_draft
    ? all_configs?.draft_styles?.styles?.onboarding?.app_color_secondary ||
      '#012616'
    : all_configs?.onboarding?.app_color_secondary || '#012616';
  const info_color = is_draft
    ? all_configs?.draft_styles?.styles?.onboarding?.app_color_info || '#087990'
    : all_configs?.onboarding?.app_color_info || '#087990';
  const footer_color = is_draft
    ? all_configs?.draft_styles?.styles?.onboarding?.bg_footer_color || '#FFF'
    : all_configs?.onboarding?.bg_footer_color || '#FFF';
  const header_color = is_draft
    ? all_configs?.draft_styles?.styles?.onboarding?.bg_header_color ?? '#FFF'
    : all_configs?.onboarding?.bg_header_color ?? '#FFF';
  const body_color = is_draft
    ? all_configs?.draft_styles?.styles?.onboarding?.bg_body_color ?? '#f6f9ff'
    : all_configs?.onboarding?.bg_body_color ?? '#f6f9ff';

  root.style.setProperty('--footer_color', footer_color);
  root.style.setProperty('--header_color', header_color);
  root.style.setProperty('--primary_color', primary_color);
  root.style.setProperty('--primary_light', primary_light_color);
  root.style.setProperty('--secondary_color', secondary_color);
  root.style.setProperty('--info_color', info_color);
  root.style.setProperty('--body_color', body_color);

  //
  all_integrations_data.google_tag_manager = is_draft
    ? all_configs?.draft_styles?.styles?.integrations?.google_tag_manager
    : all_configs?.integrations?.google_tag_manager;
  all_integrations_data.facebook_pixel =
    all_configs?.integrations?.facebook_pixel;

  // direction set
  TYPE_CONSTANT.DIR = getCookie(`${TYPE_CONSTANT.DOMAIN}_direction`);
  // SEO
  TYPE_CONSTANT.META_TITLE = is_draft
    ? all_configs?.draft_styles?.styles?.seo?.meta_title || ''
    : all_configs?.seo?.meta_title || '';
  TYPE_CONSTANT.META_DESCRIPTIONS = is_draft
    ? all_configs?.draft_styles?.styles?.seo?.meta_description
    : all_configs?.seo?.meta_description || '';
  TYPE_CONSTANT.META_ACCOUNT_TITLE = is_draft
    ? all_configs?.draft_styles?.styles?.seo?.meta_account_title || ''
    : all_configs?.seo?.meta_account_title || '';
  TYPE_CONSTANT.META_LISTING_TITLE = is_draft
    ? all_configs?.draft_styles?.styles?.seo?.meta_listing_title || ''
    : all_configs?.seo?.meta_listing_title || '';
  TYPE_CONSTANT.META_LISTING_DESCRIPTION = is_draft
    ? all_configs?.draft_styles?.styles?.seo?.meta_listing_description || ''
    : all_configs?.seo?.meta_listing_description || '';
  TYPE_CONSTANT.META_LISTING_CATEGORY_TITLE = is_draft
    ? all_configs?.draft_styles?.styles?.seo?.meta_listing_category_title || ''
    : all_configs?.seo?.meta_listing_category_title || '';
  TYPE_CONSTANT.META_LISTING_CATEGORY_DESCRIPTION = is_draft
    ? all_configs?.draft_styles?.styles?.seo
        ?.meta_listing_category_description || ''
    : all_configs?.seo?.meta_listing_category_description || '';

  // SET CONFIGS IN WEB CONSTANT
  TYPE_CONSTANT.GENERAL_CONFIGS = is_draft
    ? all_configs?.draft_styles?.styles?.general ?? {}
    : all_configs?.general ?? {};
  TYPE_CONSTANT.ONBOARDING_CONFIGS = is_draft
    ? all_configs?.draft_styles?.styles?.onboarding ?? {}
    : all_configs?.onboarding ?? {};
  TYPE_CONSTANT.ACCOUNTS_CONFIGS = is_draft
    ? all_configs?.draft_styles?.styles?.accounts ?? {}
    : all_configs?.accounts ?? {};
  TYPE_CONSTANT.LISTINGS_CONFIGS = is_draft
    ? all_configs?.draft_styles?.styles?.listings ?? {}
    : all_configs?.listings ?? {};
  TYPE_CONSTANT.ALL_INTEGRATIONS = is_draft
    ? all_configs?.draft_styles?.styles?.integrations ?? {}
    : all_configs?.integrations ?? {};
  TYPE_CONSTANT.PAYMENT_CONFIGS = is_draft
    ? all_configs?.draft_styles?.styles?.payments ?? {}
    : all_configs?.payments ?? {};
  TYPE_CONSTANT.DRAFT_STYLES = all_configs?.draft_styles ?? {};
  TYPE_CONSTANT.SEO_CONFIGS = is_draft
    ? all_configs?.draft_styles?.styles?.seo ?? {}
    : all_configs?.seo ?? {};
  TYPE_CONSTANT.STYLES_CONFIGS = is_draft
    ? all_configs?.draft_styles?.styles?.styles ?? {}
    : all_configs?.styles ?? {};
  TYPE_CONSTANT.SOCIAL_CONFIGS = is_draft
    ? all_configs?.draft_styles?.styles?.social ?? {}
    : all_configs?.social ?? {};
  TYPE_CONSTANT.ADDRESS_CONFIGS = is_draft
    ? all_configs?.draft_styles?.styles?.address ?? {}
    : all_configs?.address ?? {};
  TYPE_CONSTANT.ORDERS_CONFIGS = is_draft
    ? all_configs?.draft_styles?.styles?.orders ?? {}
    : all_configs?.orders ?? {};
  TYPE_CONSTANT.SHIPPING_CONFIGS = is_draft
    ? all_configs?.draft_styles?.styles?.shipping ?? {}
    : all_configs?.shipping ?? {};
  TYPE_CONSTANT.PAGES = is_draft
    ? all_configs?.draft_styles?.styles?.pages ?? {}
    : all_configs?.pages ?? {};

  // Header Styles

  header_styles_configs({ is_draft, all_configs });

  // Body styles
  const body_styles = is_draft
    ? all_configs?.draft_styles?.styles?.styles?.body ?? {}
    : all_configs?.styles?.body ?? {};
  root.style.setProperty(
    '--bodyStylesMaxWidth',
    body_styles?.max_width ?? '1400px'
  );
  root.style.setProperty(
    '--bodyStylesPaddingX',
    body_styles?.padding_x ?? '16px'
  );
  root.style.setProperty(
    '--bodyStylesPaddingY',
    body_styles?.padding_y ?? '16px'
  );
  root.style.setProperty(`--page_default_gap`, body_styles?.gap ?? '24px');

  // Listing card styles
  listing_card_config_setup({ is_draft, all_configs });
  // Account card styles
  account_card_config_setup({ is_draft, all_configs });

  const listing_card_styles = is_draft
    ? all_configs?.draft_styles?.styles?.styles?.listing_card
    : all_configs?.styles?.listing_card;

  root.style.setProperty(
    `--listing_card_height`,
    listing_card_styles?.height
      ? listing_card_styles?.height
      : listing_card_default_style(all_configs?.general?.theme)
  );
  root.style.setProperty(
    `--listing_card_width`,
    listing_card_styles?.width
      ? listing_card_styles?.width
      : listing_card_default_style(all_configs?.general?.theme)
  );

  //Typography STYLES
  typography_configs({ is_draft, all_configs });

  // Home category card
  const home_category_card_styles = is_draft
    ? all_configs?.draft_styles?.styles?.styles?.home?.category_card
    : all_configs?.styles?.home?.category_card;

  root.style.setProperty(
    `--home_category_card_height`,
    home_category_card_styles?.height
      ? home_category_card_styles?.height
      : '100px'
  );
  root.style.setProperty(
    `--home_category_card_width`,
    home_category_card_styles?.width
      ? home_category_card_styles?.width
      : '100px'
  );
  root.style.setProperty(
    `--home_category_image_height`,
    home_category_card_styles?.image_height
      ? home_category_card_styles?.image_height
      : '75%'
  );

  //Input fields
  const input_fields_styles = is_draft
    ? all_configs?.draft_styles?.styles?.styles?.form_styles?.input_fields
    : all_configs?.styles?.form_styles?.input_fields;

  root.style.setProperty(
    `--input_fields_border_radius`,
    input_fields_styles?.border_radius
      ? input_fields_styles?.border_radius
      : '6px'
  );

  // Button styles
  const button_styles = is_draft
    ? all_configs?.draft_styles?.styles?.styles?.button_styles
    : all_configs?.styles?.button_styles;

  root.style.setProperty(
    `--button_styles_border_radius`,
    button_styles?.border_radius ? button_styles?.border_radius : '6px'
  );
};

// SET configs in web constant file :
// export const set_constant_configs = (all_configs) => {

// };

export const ssr_configs_set = ({ is_draft, all_configs }) => {
  //fonts set
  metadata.primary_font_name = is_draft
    ? all_configs?.draft_styles?.styles?.general?.web_primary_font ||
      'Montserrat'
    : all_configs?.general?.web_primary_font || 'Montserrat';

  metadata.body_font_name = is_draft
    ? all_configs?.draft_styles?.styles?.general?.web_font_title || 'Montserrat'
    : all_configs?.general?.web_font_title || 'Montserrat';
  metadata.title_font_name = is_draft
    ? all_configs?.draft_styles?.styles?.general?.web_font_body || 'Montserrat'
    : all_configs?.general?.web_font_body || 'Montserrat';

  // favicon
  metadata.favicon = is_draft
    ? all_configs?.draft_styles?.styles?.general?.web_icon
    : all_configs?.general?.web_icon;

  // All Integrations
  TYPE_CONSTANT.ALL_INTEGRATIONS = is_draft
    ? all_configs?.draft_styles?.styles?.integrations ?? {}
    : all_configs?.integrations ?? {};
};
